import CryptoJS from "crypto-js";
import { cloneDeep } from "lodash";
import { sleep } from "@/utils/fn";

// 缓存数据类
class CacheData {
    // 等待赋值
    public waiting = Symbol("waitingValue");

    private cacheMap: Map<string, { data: any; timestamp: number; expire: number }>;

    // 是否开启了检查
    private isChecking = false;

    constructor() {
        this.cacheMap = new Map();
    }

    // 生成缓存key
    static generateKey(method: string, url: string, params: any): string {
        return CryptoJS.MD5(`${method}:${url}?${JSON.stringify(params)}`).toString();
        // return `${method}:${url}?${CryptoJS.MD5(JSON.stringify(params)).toString()}`;
    }

    // 设置缓存
    public set(key: string, value: any, expire: number = 0): void {
        this.cacheMap.set(key, {
            data: value === this.waiting ? value : cloneDeep(value),
            timestamp: Date.now(),
            expire: Number(expire) * 1000,
        });

        this.cleanup();
    }

    // 获取缓存
    public get(key: string): any {
        const cache = this.cacheMap.get(key);
        if (!cache) return null;

        // 检查是否过期
        if (cache.expire > 0 && Date.now() - cache.timestamp > cache.expire) {
            this.cacheMap.delete(key);
            return null;
        }

        return cache.data === this.waiting ? cache.data : cloneDeep(cache.data);
    }

    // 删除缓存
    public delete(key: string): void {
        this.cacheMap.delete(key);
    }

    // 清空缓存
    public clear(): void {
        this.cacheMap.clear();
    }

    // 清理过期数据
    private async cleanup(isInnerLoop: boolean = false): Promise<void> {
        // 如果正在检查，则不进行检查
        if (this.isChecking && !isInnerLoop) return;
        this.isChecking = true;

        const now = Date.now();
        for (const [key, cache] of this.cacheMap.entries()) {
            if (cache.expire > 0 && now - cache.timestamp > cache.expire) {
                this.delete(key);
            }
        }

        if (this.cacheMap.size) {
            await sleep(1);
            return this.cleanup(true);
        }

        this.isChecking = false;
    }
}

export const useCache = () => {
    const cache = new CacheData();

    return {
        cache,
        generateKey: CacheData.generateKey,
    };
};
