import { nextTick } from "vue";
import { MessagePlugin } from "tdesign-vue-next";
import XLSX from "xlsx";
import MD5 from "browser-md5-file";
import CryptoJS from "crypto-js";

import { generatePresignedUrl } from "@/utils/upload";
import type { ExportExcelFun } from "#/importFile";

const MD5Lib = new MD5();

// 防抖集
const debounceSets = new Set();

/**
 * @Author: wangzesen
 * @Description: 防抖工具函数
 * @Date: 2021-10-13 09:45:16
 * @param {string} key 唯一key
 * @param {number} dt 延时
 * @return {boolean} 是否触发防抖
 */
export function debounce(key: string | symbol = "", dt = 0.3) {
    if (debounceSets.has(key)) {
        return true;
    }

    debounceSets.add(key);
    setTimeout(() => debounceSets.delete(key), dt * 1000);

    return false;
}

// 动态挂载css或js
export async function loadExternalResources(urls: string | string[]) {
    let list = Array.isArray(urls) ? urls : [urls];

    let promises = list.map(
        (url) =>
            new Promise((resolve) => {
                let md5 = CryptoJS.MD5(url).toString();

                // 如果已经加载过了
                if (document.getElementById(md5)) {
                    return resolve(null);
                }

                let element: HTMLLinkElement | HTMLScriptElement | null = null;

                let extension = url.match(/\.([0-9a-z]+)(?:[\\?#]|$)/i)?.[0] || "";

                if (extension.includes("?")) {
                    extension = extension.substring(0, extension.lastIndexOf("?"));
                }

                switch (extension) {
                    case ".css":
                        element = document.createElement("link");
                        element.rel = "stylesheet";
                        element.type = "text/css";
                        element.href = url;
                        break;

                    case ".js":
                        element = document.createElement("script");
                        element.type = "text/javascript";
                        element.src = url;
                        break;

                    default:
                        break;
                }

                if (!element) {
                    return resolve(null);
                }

                element.id = md5;
                element.onload = resolve;
                document.head.appendChild(element);
            }),
    );

    await Promise.all(promises);
}

/**
 * @Author: SAM
 * @Description: 休眠方法
 * @Date: 2022-07-21 14:21:29
 * @param {number} dt 休眠秒数
 * @return {void} 无
 */
export function sleep(dt = 1) {
    return new Promise((resolve) => setTimeout(resolve, dt * 1000));
}

/**
 * @Author: SAM
 * @Description: 等待循环
 * @Date: 2023-03-02 14:38:43
 * @param {function} validate 需要等待的校验函数
 * @param {number} max 最大循环次数
 * @param {number} dt 循环等待时间（秒）
 * @return {void} 无
 */
export async function awaitWhile(validate: Function, { max = 60, dt = 0.1 } = {}) {
    // 做去重处理，等待max次
    let count = 0;
    while (validate() && count < max) {
        await sleep(dt);
        count++;
    }
}

/**
 * @Author: SAM
 * @Description: 获取文件MD5值
 * @Date: 2022-08-03 15:21:46
 * @param {File} file 文件对象
 * @return {Promise<string>} 文件md5值
 */
export function getFileMD5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        MD5Lib.md5(file, (err: any, md5: string) => {
            if (err) {
                return reject(err);
            }

            resolve(md5);
        });
    });
}

/**
 * @Author: SAM
 * @Description: 生成UUID
 * @Date: 2021-10-15 13:58:19
 * @param {string} template 要生成的格式,x和y会被替换成随机字母，其他的保留原有字符
 * @return {string} uuid
 */
export function guid(template = "xxxxxxxx-xxxx-yxxx-yxxx-xxxxxxxxxxxx") {
    return template.replace(/[xy]/g, function (c) {
        let r = (Math.random() * 16) | 0;
        let v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/**
 * @Author: wangzesen
 * @Description: 填充前缀方法
 * @Date: 2021-04-07 14:57:45
 * @param {number|string} val 需要填充前缀的内容
 * @param {number} len 至少补齐到几位
 * @param {number|string} pad 用于填充的前缀内容
 * @return {*}
 */
export function padStart(val: string | number, len = 2, pad = 0) {
    return val.toString().padStart(len, pad.toString());
}

/**
 * @Author: wangzesen
 * @Description: 获取/格式化日期
 * @Date: 2021-04-29 11:46:55
 * @param {null|number|string} dt 时间戳或日期文本
 * @param {string} template 要转换成的模板
 * @return {string} 转换后的文本
 */
export function getDate(dt: string | number | Date, template = "YYYY-MM-DD", needPadStart = true) {
    if (!dt) {
        return "-";
    }

    let nowDate;

    switch (typeof dt) {
        case "object":
            nowDate = dt;
            break;

        case "string":
            nowDate = Number(dt) ? new Date(Number(dt)) : new Date(dt.replace(/-/g, "/"));
            break;

        case "number":
            nowDate = new Date(dt);
            break;

        default:
            break;
    }

    if (!nowDate) {
        nowDate = new Date();
    }

    let MM = nowDate.getMonth() + 1;
    let DD = nowDate.getDate();
    let hh = nowDate.getHours();
    let mm = nowDate.getMinutes();
    let ss = nowDate.getSeconds();

    let dateNodes = {
        YYYY: String(nowDate.getFullYear()),
        MM: needPadStart ? padStart(String(MM)) : MM,
        DD: needPadStart ? padStart(String(DD)) : DD,
        hh: needPadStart ? padStart(String(hh)) : hh,
        mm: needPadStart ? padStart(String(mm)) : mm,
        ss: needPadStart ? padStart(String(ss)) : ss,
    };

    let str = template;

    for (let key in dateNodes) {
        str = str.replace(key, (dateNodes as any)[key]);
    }

    return str;
}

/**
 * @Author: SAM
 * @Description: 秒转换成日期
 * @Date: 2022-09-24 15:26:42
 * @param {number} time 秒
 * @return {string} 转换后的时间
 */
export function secondToDate(time: string | number, template = "hh:mm:ss") {
    let __time__ = Number(time);
    // 取小时
    let hh = Math.floor(__time__ / 3600);
    // 取分钟
    let mm = Math.floor((__time__ % 3600) / 60);
    // 取秒
    let ss = Math.floor(__time__ % 60);

    let dateNodes = {
        hh: padStart(hh),
        mm: padStart(mm),
        ss: padStart(ss),
    };

    let str = template;

    for (let key in dateNodes) {
        str = str.replace(key, dateNodes[key as keyof typeof dateNodes]);
    }

    return str;
}

// 获取视频缩略图
export function getVideoThumbUrl(url: string, frameTimeInSeconds = 0, refresh = false) {
    if (!url) {
        return url;
    }

    let newUrl = url;

    // 是什么平台平台
    let __platform__ = newUrl.includes("aliyuncs.com") ? "oss" : "cos";

    // 使用的什么平台的压缩规则
    switch (__platform__) {
        // 腾讯云
        case "cos":
            newUrl += "?ci-process=snapshot&time=1&format=jpg";
            break;

        // 阿里云
        case "oss":
            newUrl += `?x-oss-process=video/snapshot,t_${Math.floor(frameTimeInSeconds * 1000)},f_jpg${refresh ? "" : `,m_fast&_r=${Math.random()}`}`;
            break;

        default:
            break;
    }

    return newUrl;
}

/**
 * @Author: SAM
 * @Description: 等待下一帧（count为等待次数）
 * @Date: 2022-07-21 14:21:29
 * @return {void} 无
 */
export function awaitNextTick(count = 1) {
    return new Promise((resolve: any) => {
        let _nextTick = () => {
            count--;

            if (count === 0) {
                return resolve();
            }

            nextTick(_nextTick);
        };
        nextTick(_nextTick);
    });
}

/**
 * @Author: SAM
 * @Description: 清空数据
 * @Date: 2022-12-07 14:08:27
 * @param {any[]} arr 要清空的数组
 * @param {?any[]} newArr 清空后要填充的数组数据
 * @return {void} 无
 */
export function clearArray(arr: any[], newArrData?: any[]) {
    while (arr.length) {
        arr.pop();
    }

    if (newArrData) {
        arr.push(...newArrData);
    }
}

// 导出excel
export function toExcel(titleArr: string[], dataArr: any[], excelName = getDate(Date.now(), "YYYY-MM-DD"), wsName = "Sheet1") {
    let filename = excelName + ".xlsx"; // 文件名称
    let data = [titleArr, ...dataArr]; // 数据，一定注意需要时二维数组
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(data);

    const colWidth = dataArr.map((row) =>
        row.map((val: any) => {
            /* 先判断是否为null/undefined*/
            if (val === null || val === undefined || !val) {
                return {
                    wch: 0,
                };
            } else if (val.toString().charCodeAt(0) > 255) {
                /* 再判断是否为中文*/
                return {
                    wch: val.toString().length * 2,
                };
            }
            return {
                wch: val.toString().length,
            };
        }),
    );

    // 表头宽度
    const titleWidth = titleArr.map((val) => {
        return {
            wch: val.toString().length * 2,
        };
    });

    /* 以表头宽度为初始值*/
    let result = titleWidth;

    for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
            if (result[j].wch < colWidth[i][j].wch) {
                result[j].wch = colWidth[i][j].wch > 256 ? 256 : colWidth[i][j].wch;
            }
        }
    }

    ws["!cols"] = result;

    XLSX.utils.book_append_sheet(wb, ws, wsName); // 将数据添加到工作薄
    XLSX.writeFile(wb, filename);
}

/**
 * @Author: wangxiaomin
 * @Description: 获取全部导出的数据
 * @Date: 2022-01-06 15:53:35
 * @return {void} 无
 */
export const exportExcel: ExportExcelFun = async (excelData, filterFunc, filterListFunc) => {
    let {
        total,
        titles,
        keys,
        api,
        list,
        pageSize = 500,
        QPS = 10,
        params = {},
        fileName,
        fullFileName,
        time = getDate(Date.now(), "YYYY-MM-DD") as string,
        PNKey = "pageNo",
        PSKey = "pageSize",
        resKey = "list",
    } = excelData as any;

    if (!total) {
        setTimeout(() => MessagePlugin.warning("暂无数据可导出"), 0);
        return;
    }

    if (total > 20000) {
        setTimeout(() => MessagePlugin.warning("单次最多支持导出两万条数据，请重新筛选后再导出"), 0);
        return;
    }

    // 请求结果集合
    let dataList: any[] = [];

    if (list) {
        dataList = list;
    } else {
        if (!total || !api) {
            return;
        }

        // 要请求的条件
        let requstNum = Math.ceil(total / pageSize);

        // 记录
        let curCount = 0; // 当前请求完成次数
        let startIndex = 0; // 请求开始位置

        // 填充请求队列
        while (curCount < requstNum) {
            const reqQueue = []; // 请求队列
            // 每次请求个数
            let count = Math.min(QPS, requstNum - curCount);
            // count + startIndex ===》 结束位置
            for (let i = startIndex; i < count + startIndex; i++) {
                reqQueue.push(
                    api({
                        ...params,
                        [PNKey]: i + 1,
                        [PSKey]: pageSize,
                    }),
                );
            }
            // 请求完成后加上开始位置和完成次数
            curCount += count;
            startIndex = curCount;
            dataList = dataList.concat(...(await Promise.all(reqQueue)).map((v) => v[resKey] || []));
        }
    }

    // 调用自定义过滤
    if (filterFunc) {
        for (let key in dataList) {
            let newItem = filterFunc(dataList[key]);

            if (newItem) {
                dataList[key] = newItem;
            }
        }
    }

    // 在极特殊情况下，需要对整个数据进行过滤，目前在哪些地方使用：
    if (filterListFunc) {
        dataList = filterListFunc(dataList);
    }

    // 取标题与数据的key
    let [xlsTitle, dataKey] = typeof titles[0] === "string" ? [titles, keys] : [titles.map((v: any) => v.title), titles.map((v: any) => v.dataIndex || v.key)];

    // 文件名
    let exportFileName = fullFileName ? fullFileName : fileName ? `${fileName} ${time}` : time;

    // 导入excel
    toExcel(
        xlsTitle,
        dataList.map((v) => dataKey.map((key: any) => v[key])),
        exportFileName,
    );
};

// 转换树结构
export const convertTree = (treeData: any[], { parentId = null, parentIdKey = "", idKey = "id", childrenKey = "children", list = new Map() as any[] | Map<string, any> } = {}) => {
    // 遍历树
    for (let i = 0; i < treeData.length; i++) {
        // 当前树节点
        let tree = treeData[i];

        if (Array.isArray(list)) {
            // 推入数组
            list.push(tree);
        } else {
            list.set(tree[idKey], tree);
        }

        // 是否要记录父级ID
        if (parentIdKey) {
            tree[parentIdKey] = parentId;
        }

        // 判断是否要记录兄弟ID
        if (i) {
            let preTree = treeData[i - 1];
            // 给当前tree记录上一个兄弟的ID
            tree.__preId__ = preTree[idKey];
            // 给上一个tree记录当前tree的ID
            preTree.__nextId__ = tree[idKey];
        }

        // 是否要递归子树
        if (tree[childrenKey]?.length) {
            let params = { parentId: tree[idKey], parentIdKey, idKey, childrenKey, list };
            convertTree(tree[childrenKey], params);
        }
    }

    // 返回树节点数组
    return list;
};

// 扁平化树结构
export function treeToArray(treeData: any[], params = {}) {
    return convertTree(treeData, { ...params, list: [] });
}

// 扁平化树结构
export function treeToMap<T = any>(treeData: any[], params = {}) {
    return convertTree(treeData, { ...params, list: new Map() }) as Map<string, T>;
}

// 滚动到可视区域
export function scrollIntoView(el: HTMLElement | string) {
    return new Promise((resolve, reject) => {
        let tagElement = typeof el === "string" ? document.querySelector(el) : el;

        if (!tagElement) {
            return reject(new Error(`元素不存在: ${el}`));
        }

        // 监听滚动结束
        const handleScrollEnd = () => {
            tagElement.parentElement!.removeEventListener("scrollend", handleScrollEnd);
            resolve(true);
        };

        tagElement.parentElement?.addEventListener("scrollend", handleScrollEnd);

        tagElement?.scrollIntoView({ behavior: "smooth", block: "start" });
    });
}

// 获取片段时长
export const getClipDuration = (item: { effectiveIntervalSecond: [number, number] }) => {
    let [startTime, endTime] = item.effectiveIntervalSecond;
    let duration = endTime - startTime;

    return {
        duration,
        durationText: secondToDate(duration, "mm:ss"),
    };
};

// 获取光标位置
export const getCaretPosition = (element: HTMLElement) => {
    const selection = window.getSelection();
    if (!selection?.rangeCount) return null;

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
};

// 设置光标位置
export const setCaretPosition = (element: HTMLElement, position: number = 0) => {
    // 设置焦点
    element.focus();

    // 创建范围
    const range = document.createRange();
    const sel = window.getSelection();

    // 确保元素有文本节点
    if (!element.firstChild) {
        element.appendChild(document.createTextNode(""));
    }

    // 设置光标位置
    range.setStart(element.firstChild!, position);
    range.collapse(true);

    // 清除现有选区并应用新选区
    sel?.removeAllRanges();
    sel?.addRange(range);
};

/**
 * @Author: SAM
 * @Description: 下载文件
 * @Date: 2022-11-07 11:06:48
 * @param {string} url 下载链接
 * @param {string?} newFileName 文件重命名
 * @return {void} 无
 */
export async function downloadFile(url: string, newFileName?: string) {
    // base64格式
    if (url.startsWith("data:")) {
        // 创建一个a标签
        const link = document.createElement("a");
        link.href = url;
        // 从base64中获取文件类型
        const fileType = url.split(";")[0].split("/")[1];
        // 生成当前日期作为文件名 YYYY-MM-DD
        const date = new Date().toISOString().split("T")[0];
        link.download = `${newFileName || date}.${fileType}`;
        document.body.appendChild(link);

        // 触发点击下载
        link.click();

        // 清理DOM
        document.body.removeChild(link);

        return;
    }

    // 阿里云oss
    if (url.includes("aliyuncs.com")) {
        let signedUrl = await generatePresignedUrl(url, newFileName);
        window.open(signedUrl, "_blank");
        return;
    }

    // 其他默认直接打开下载
    window.open(url, "_blank");
}

// 转换时间参数
export const convertDateParam = (e: string[]) => {
    let [createTimeStart, createTimeEnd] = e;

    if (createTimeStart) {
        createTimeStart = `${createTimeStart} 00:00:00`;
        createTimeEnd = `${createTimeEnd} 23:59:59`;
    }
    return { createTimeStart, createTimeEnd };
};

// 判断两个内容是否相等
export function equationValue(val1: any, val2: any) {
    return JSON.stringify(val1) === JSON.stringify(val2);
}
