import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Index",
        meta: { title: "创作" },
        component: () => import(/* webpackChunkName: "layout" */ "@/layout/index.vue"),
        children: [
            {
                path: "",
                name: "Home",
                component: () => import(/* webpackChunkName: "index" */ "@/views/home/index.vue"),
            },
            // 生成中心页面
            {
                path: "generate",
                name: "Generate",
                component: () => import(/* webpackChunkName: "generate" */ "@/views/generate/index.vue"),
            },
        ],
    },
    {
        path: "/video",
        name: "Video",
        meta: { title: "视频" },
        component: () => import(/* webpackChunkName: "common" */ "@/layout/index.vue"),
        children: [
            {
                path: "",
                name: "VideoList",
                component: () => import(/* webpackChunkName: "videoList" */ "@/views/video/list.vue"),
            },
        ],
    },
    {
        path: "/script",
        name: "Script",
        meta: { title: "脚本" },
        component: () => import(/* webpackChunkName: "common" */ "@/layout/index.vue"),
        children: [
            {
                path: "",
                name: "ScriptList",
                component: () => import(/* webpackChunkName: "scriptList" */ "@/views/script/list.vue"),
            },
        ],
    },
    {
        path: "/material",
        name: "Material",
        meta: { title: "素材" },
        component: () => import(/* webpackChunkName: "common" */ "@/layout/index.vue"),
        children: [
            {
                path: "",
                name: "MaterialList",
                component: () => import(/* webpackChunkName: "materialList" */ "@/views/material/list.vue"),
            },
        ],
    },
    // {
    //     path: "/task",
    //     name: "Task",
    //     meta: { title: "任务" },
    //     component: () => import(/* webpackChunkName: "common" */ "@/layout/index.vue"),
    //     children: [
    //         {
    //             path: "",
    //             name: "Task",
    //             component: () => import(/* webpackChunkName: "task" */ "@/views/task/list.vue"),
    //         },
    //     ],
    // },
];

// 登录路径
export const loginRoutePath = "/login";

// 切换登录路径
export const switchLoginRoutePath = "/login/switch/oceanengine";

// 白名单路径列表
export const allowPathList = [loginRoutePath, switchLoginRoutePath];

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        // 外部跳转登录
        {
            path: "/login/switch/:token",
            name: "SwitchLogin",
            meta: { hideInMenu: true },
            component: () => import(/* webpackChunkName: "switchLogin" */ "@/views/user/login/switchLogin.vue"),
        },
        // 登录页
        {
            path: loginRoutePath,
            name: "Login",
            meta: {
                title: "登录",
                keepAlive: true,
                requireAuth: false,
            },
            component: () => import(/* webpackChunkName: "user" */ "@/views/user/login/index.vue"),
        },
        // 不存在路径匹配
        {
            path: "/:pathMatch(.*)",
            name: "PathMatch",
            component: () => import(/* webpackChunkName: "exception" */ "@/views/exception/404.vue"),
        },
        ...routes,
    ],
    scrollBehavior: (to, from) => {
        if (to.path !== from.path) {
            setTimeout(() => {
                document.getElementById("app")!.scrollTop = 0;
            });
        }
        return { top: 0 };
    },
});
