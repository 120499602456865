import { NotifyPlugin } from "tdesign-vue-next";

import router, { loginRoutePath, switchLoginRoutePath, allowPathList } from "./index";
import { useAppStore } from "@/store/app";
import { useUserStore } from "@/store/user";

router.beforeEach(async (to) => {
    // 应用状态机
    const appStore = useAppStore();
    // 用户状态机
    const userStore = useUserStore();

    // 从本地取token
    let userToken = userStore.token;

    // 是否在白名单内
    let inAllowList = allowPathList.includes(to.path as string);

    // 白名单的直接放行
    if (inAllowList) {
        return true;
    }

    // 如果token不存在
    if (!userToken) {
        // 如果是群峰嵌入，则跳转别的登录页
        if (appStore.isOceanengine) {
            // 未登录，进入到登录页
            return {
                path: switchLoginRoutePath,
                replace: true,
            };
        }

        NotifyPlugin.error({ title: "错误", content: "请先登录" });

        // 未登录，进入到登录页
        return {
            path: loginRoutePath,
            replace: true,
            // 登录后跳转到之前页面，如不需要，直接删除 query 即可
            query: { redirect: encodeURIComponent(to.fullPath) },
        };
    }

    // 进行初始化配置
    if (!appStore.globalConfig.__state__) {
        userStore.GET_USER_INFO();
        await appStore.INIT_CONFIGER();
    }

    return true;
});
