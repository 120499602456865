import { get, post } from "@/api/index";
import { HEADERS_AUTH, HEADERS_CRM_AI } from "../ServerMap";

// 获取登录二维码 正式版为 "release"，体验版为 "trial"，开发版为 "develop", source: 来源 1:pc管理后台 2: pc客户端
export const createLoginQRCode = (env: "release" | "trial" | "develop") => post("/api/ai/login/qrcode", { env, source: 2 }, HEADERS_CRM_AI);

// 获取二维码登录结果
export const getQRCodeResult = (requestId: string) => get("/api/ai/login/qrcode", { requestId }, HEADERS_CRM_AI);

// 外部登录
export const switchLogin = (token: string) => post("/api/auth/switch-login", { token }, HEADERS_AUTH);

// 获取用户信息
export const getUserInfo = () => get("/api/ai/user/userInfo", null, HEADERS_CRM_AI);

// 群峰登录
export const loginWithOceanengine = (data: AnyObject) => get("/api/ai/user/login", data, HEADERS_CRM_AI);

// 获取群峰用户信息
export const getOceanengineUserInfo = () => post("/api/ai/qun-feng/order/check", null, HEADERS_CRM_AI);
