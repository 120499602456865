import { acceptHMRUpdate, defineStore } from "pinia";

import { getGlobalConfig } from "@/api/config";
import { updateImgConfig } from "@/hooks/useImages";

export const useAppStore = defineStore("app", {
    persist: {
        // 指定需要持久化的状态
        pick: ["isCloseBrowserCompatibilityNotice"],
    },

    state: () => {
        return {
            // 品牌ID
            brandId: "",

            // 是否为群峰嵌入
            isOceanengine: window.self !== window.top,

            // 是否关闭了浏览器兼容性提示
            isCloseBrowserCompatibilityNotice: false,

            // 全局配置
            globalConfig: {
                // 是否完成初始化
                __state__: false,
            },
        };
    },
    getters: {
        // 运行环境
        ENV_MODE: () => import.meta.env.MODE,
    },

    actions: {
        SET_BRAND_ID(id: string) {
            this.brandId = String(id);
        },

        // 初始化配置
        async INIT_CONFIGER() {
            let { storage } = await getGlobalConfig();

            // 压缩图配置
            updateImgConfig(storage);

            // 标识初始化完成
            this.globalConfig.__state__ = true;
        },

        // 关闭浏览器兼容性提示
        CLOSE_BROWSER_COMPATIBILITY_NOTICE() {
            this.isCloseBrowserCompatibilityNotice = true;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
    // import.meta.hot.accept(() => location.reload());
}
