import { get, post } from "@/api/index";
import { HEADERS_COMMON, HEADERS_WEB } from "../ServerMap";
import { useAppStore } from "@/store/app";

// 获取上传凭证
export const getUploadToken = (modelName: string | number = "unknow") => get("/api/ai/common/upload/credential", { modelName }, { ...HEADERS_COMMON, bid: useAppStore().brandId, cacheDuration: 60 });

// 代理下载
export const getDownloadFile = (url: string, newFileName = "") => get("/web/content/download/", { url, newFileName }, { ...HEADERS_WEB, customError: true, responseType: "blob" });

// 代理上传
export const uploadFileForUrl = (url: string, module: string) => post("/api/upload/url", { url, module }, { ...HEADERS_COMMON, timeout: 30000 });
