import CryptoJS from "crypto-js";
import qs from "qs";
import { AxiosResponse, AxiosRequestConfig } from "axios";

const { VUE_APP_PROXY_KEY, VUE_APP_SECRET_ID, VUE_APP_SECRET_KEY } = import.meta.env;

// 转义
function eccode(data: any) {
    let dataType = typeof data;
    let dataString = data;

    // 如果是对象
    if (dataType === "object" && !Array.isArray(data)) {
        dataString = JSON.stringify(data);
        // 如果是字符串，且包含%，则先转义%
    } else if (dataType === "string" && dataString.includes("%")) {
        dataString = dataString.replace(/%/g, "%25");
    }

    // eslint-disable-next-line no-useless-escape
    return encodeURIComponent(decodeURIComponent(dataString)).replace(/'/g, "%27").replace(/\(/g, "%28").replace(/\~/g, "%7E").replace(/\)/g, "%29");
}

// 转换QueryStr
function convertQueryStr(params: AxiosRequestConfig["params"]) {
    return Object.keys(params)
        .filter((paramName) => {
            let flag = ![undefined, null, ""].includes(params[paramName]);

            return flag;
        })
        .sort()
        .map((paramName) => eccode(paramName) + "=" + eccode(params[paramName]))
        .join("&");
}

// 请求签名方法
export function signatureLib(options: Required<AxiosRequestConfig> & { signatureMateStr: string }) {
    let timestamp = Math.round(Date.now() / 1000);

    let body = "";

    let params: AxiosRequestConfig["params"] = { ...(options.params || {}) };

    // 请求方式
    let method = options.method.toLocaleUpperCase();

    // 不同请求方式做不同的处理
    switch (method) {
        case "GET":
            break;

        case "POST":
        case "PUT":
        case "DELETE":
            // case "PATCH":

            body = typeof options.data === "string" ? options.data : options.headers["app-id"] === "card_app" ? qs.stringify(options.data) : JSON.stringify(options.data);
            break;

        default:
            break;
    }

    let queryStr = convertQueryStr(params);

    let path = options.url;

    // 处理开发环境下的提示
    if (path.startsWith(VUE_APP_PROXY_KEY)) {
        path = path.replace(VUE_APP_PROXY_KEY, "");
    }

    // 处理
    if (!path.startsWith("/")) {
        path = "/" + path;
    }

    if (queryStr.length > 0) {
        path += "?" + queryStr;
    }

    const signatureMateStr = VUE_APP_SECRET_ID + "\n" + timestamp + "\n" + method + "\n" + path + "\n" + body + "\n";

    const hmac = CryptoJS.HmacSHA256(signatureMateStr, VUE_APP_SECRET_KEY);
    const signature = hmac.toString(CryptoJS.enc.Hex);

    Object.assign(options.headers, {
        "x-secret-id": VUE_APP_SECRET_ID,
        "x-timestamp": timestamp.toString(),
        "x-signature": signature,
    });

    // 调试代码，用于快速发现接口签名错误问题，稳定后可删除。 2023-01-11
    options.signatureMateStr = signatureMateStr;

    return options;
}

// 调试代码，用于快速发现接口签名错误问题，稳定后可删除。 2023-01-11
export function signatureErrorLog(result: AxiosResponse & { config: { signatureMateStr: string } }) {
    if (result.data?.msg?.startsWith("签名失败")) {
        console.error(result.config.url + "\n\n" + result.data.msg + "\n\n前端签名\n" + result.config.signatureMateStr);
    }
}
