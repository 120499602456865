import { useLocalStorage as vueuseUseLocalStorage } from "@vueuse/core";

import { computed, WritableComputedRef } from "vue";

export const useLocalStorage = <T = any>(key: string, initialValue?: T): WritableComputedRef<T> => {
    const storedValue = vueuseUseLocalStorage<any>(key, initialValue);

    const value = computed({
        get: () => {
            if (storedValue.value === null) {
                return undefined;
            }

            try {
                return JSON.parse(storedValue.value);
            } catch (e) {
                return storedValue.value;
            }
        },
        set: (newValue) => {
            try {
                storedValue.value = JSON.stringify(newValue);
            } catch (e) {
                console.error("Error setting value in store:");
                throw e;
            }

            return newValue;
        },
    });

    return value;
};
