<template>
    <t-config-provider>
        <BrowserCompatibilityNotice />

        <RouterView />
    </t-config-provider>
</template>

<script setup lang="ts">
import BrowserCompatibilityNotice from "@/components/BrowserCompatibilityNotice/index.vue";

// import { watch } from "vue";
// import { useColorMode } from "@vueuse/core";

// 设置为暗黑模式
document.documentElement.setAttribute("theme-mode", "dark");

// // 使用 useColorMode 以检测和切换颜色模式
// const colorMode = useColorMode();

// watch(
//     colorMode,
//     (mode) => {
//         console.log("什么模式", mode);
//     },
//     { immediate: true },
// );
</script>

<style scoped lang="less"></style>
