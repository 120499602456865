<template>
    <div class="browser-compatibility-notice" v-if="(!isChrome || isQuark) && !appStore.isCloseBrowserCompatibilityNotice">
        <InfoCircleFilledIcon />
        <span>温馨提醒：当前浏览器可能存在界面显示不兼容问题，推荐使用谷歌浏览器！</span>
        <t-button size="large" @click="handleDownloadChrome">
            <template #icon>
                <img src="@/assets/images/icon/chrome-logo.png" alt="google-logo" />
            </template>
            下载谷歌浏览器
        </t-button>

        <t-button size="large" theme="default" @click="appStore.CLOSE_BROWSER_COMPATIBILITY_NOTICE">不再提醒</t-button>
    </div>
</template>

<script setup lang="ts">
import { InfoCircleFilledIcon } from "tdesign-icons-vue-next";

import { useAppStore } from "@/store/app";

const appStore = useAppStore();
// 当前是否为chrome浏览器
const isChrome = navigator.userAgent.includes("Chrome");

// 当前是否为夸克浏览器
const isQuark = navigator.userAgent.includes("Quark");

const handleDownloadChrome = () => window.open("https://google.cn/chrome", "_blank");
</script>

<style scoped lang="less">
.browser-compatibility-notice {
    background-color: #1b2129;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    > .t-icon {
        color: rgba(255, 255, 255, 0.8);
        margin-right: 10px;
    }

    > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        background: linear-gradient(270deg, #366ef4 0%, #48c1bb 25%, #ffffff 85%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    > .t-button {
        margin-left: 20px;

        > img {
            width: 20px;
            margin-right: 10px;
        }
    }
}
</style>
