import { ref } from "vue";

// 上传时要配置的域名
export const domain = ref<string>("");

// 对象存储平台
export const platform = ref<string>("");

// 更新配置
export const updateImgConfig = (storage: any) => {
    platform.value = storage?.platform;
    domain.value = `https://${storage?.domain.replace(/^(http:\/\/|https:\/\/)/, "")}`;
};

// 压缩图片方法
export const cropImgWH = (url: string, width?: number, height?: number): string => {
    // 如果还没有初始化 或 没有传入链接 或 已经压缩的链接 或 没有传入宽度
    if (!platform.value || !url || url.includes("?") || !width) {
        return url || "";
    }

    let w = parseFloat(width.toFixed(2));
    let h = height && parseFloat(height.toFixed(2));

    let newUrl = url;

    // 使用的什么平台的压缩规则
    switch (platform.value) {
        // 腾讯云
        case "cos":
            if (h) {
                newUrl += "?imageView2/1/w/" + w + "/h/" + h;
            } else {
                newUrl += "?imageView2/2/w/" + w;
            }
            break;

        // 阿里云
        case "oss":
            if (h) {
                newUrl += "?x-oss-process=image/resize,m_fill,w_" + w + ",h_" + h;
            } else {
                newUrl += "?x-oss-process=image/resize,m_lfit,w_" + w;
            }
            break;

        default:
            break;
    }

    return newUrl;
};
